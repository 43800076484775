import imgOverfishing from "../../../../src/content/blog/images/overfishing-and-bycatch-endangers-ocean ecosystems.jpg";
import imgPlasticPollution from "../../../../src/content/blog/images/ocean-plastic-pollution.jpg";
import imgRisingTemperature from "../../../../src/content/blog/images/rising-ocean-temperature-causing-coral-bleaching.jpg";
import imgAcidification from "../../../../src/content/blog/images/acidification-of-our-waters.jpg";
import imgIndustrialWaterPollution from "../../../../src/content/blog/images/water-pollution-from-industries.jpg";
import ImageContainer from "../../../../src/components/blog/ImageContainer.jsx";
import * as React from 'react';
export default {
  imgOverfishing,
  imgPlasticPollution,
  imgRisingTemperature,
  imgAcidification,
  imgIndustrialWaterPollution,
  ImageContainer,
  React
};