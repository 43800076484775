import ThreeDModelImg from "../../../../src/content/blog/images/eisbach-riders-quick-lock-touring-fin-3d-model.jpg";
import BodenseeImg from "../../../../src/content/blog/images/eisbach-riders-quick-lock-touring-fin-paddling-on-the-bodensee.jpg";
import PrototypeImg from "../../../../src/content/blog/images/eisbach-riders-quick-lock-touring-fin-prototyping.png";
import InfographImg from "../../../../src/content/blog/images/eisbach-riders-quick-lock-touring-fin-infographics.jpg";
import wasteMaterialImg from "../../../../src/content/blog/images/eisbach-riders-quick-lock-touring-fin-recycled-waste-material.jpg";
import ImageContainer from "../../../../src/components/blog/ImageContainer.jsx";
import Newsletter from "../../../../src/components/newsletter/Newsletter";
import * as React from 'react';
export default {
  ThreeDModelImg,
  BodenseeImg,
  PrototypeImg,
  InfographImg,
  wasteMaterialImg,
  ImageContainer,
  Newsletter,
  React
};