import ImageContainer from "../../../../src/components/blog/ImageContainer.jsx";
import climateShapingImg from "../../../../src/content/blog/images/climate-change-guide-for-surfers-surfboard-shaping.jpg";
import climateWetsuit from "../../../../src/content/blog/images/climate-change-guide-for-surfers-wetsuit.jpg";
import climateBeeswell from "../../../../src/content/blog/images/climate-change-guide-for-surfers-beeswell-wax-on-surfboard.jpg";
import climateSuncreen from "../../../../src/content/blog/images/climate-change-guide-for-surfers-suntribe-sunscreen.jpg";
import climateTrip from "../../../../src/content/blog/images/climate-change-guide-for-surfers-surf-trip.jpg";
import * as React from 'react';
export default {
  ImageContainer,
  climateShapingImg,
  climateWetsuit,
  climateBeeswell,
  climateSuncreen,
  climateTrip,
  React
};