import imgCommonplastic from "../../../../src/content/blog/images/common-ocean-plastic.jpg";
import imgWorstplastic from "../../../../src/content/blog/images/worst-types-of-plastic.jpg";
import imgUnseenplastic from "../../../../src/content/blog/images/ocean-plastic-pollution-we-dont-see.jpg";
import imgDecomposeplastic from "../../../../src/content/blog/images/ocean-plastic-decomposes.jpg";
import imgImpactoceanplastic from "../../../../src/content/blog/images/impact-of-ocean-plastic-on-our-world.jpg";
import imgPlasticfree from "../../../../src/content/blog/images/plastic-free-lifestyle.jpg";
import imgPlasticprojects from "../../../../src/content/blog/images/projects-tackling-plastic-pollution.jpg";
import ImageContainer from "../../../../src/components/blog/ImageContainer.jsx";
import * as React from 'react';
export default {
  imgCommonplastic,
  imgWorstplastic,
  imgUnseenplastic,
  imgDecomposeplastic,
  imgImpactoceanplastic,
  imgPlasticfree,
  imgPlasticprojects,
  ImageContainer,
  React
};