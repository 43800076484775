import imgQuickLockTouring from "../../../../src/content/blog/images/eisbach-riders-sustainable-fin-quick-lock-fin-packaging.jpg";
import imgUSBoxTouring from "../../../../src/content/blog/images/eisbach-riders-sustainable-fin-touring-us-box-lifestyle.jpg";
import imgUSBoxClassic from "../../../../src/content/blog/images/eisbach-riders-sustainable-fin-9-inch-us-box-grass.jpg";
import imgUSBoxClassicPaddling from "../../../../src/content/blog/images/eisbach-riders-sustainable-fin-9-inch-us-box-lifestyle-winter.jpg";
import imgERSUPFins from "../../../../src/content/blog/images/eisbach-riders-sustainable-fin-range.jpg";
import ImageContainer from "../../../../src/components/blog/ImageContainer.jsx";
import Video from "../../../../src/components/blog/Video.jsx";
import * as React from 'react';
export default {
  imgQuickLockTouring,
  imgUSBoxTouring,
  imgUSBoxClassic,
  imgUSBoxClassicPaddling,
  imgERSUPFins,
  ImageContainer,
  Video,
  React
};