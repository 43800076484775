import imgVanDerWaalTeam from "../../../../src/content/blog/images/product-insights-van-der-waal-surfboard-traction-matrim-and-team.jpg";
import imgYellowSurfboard from "../../../../src/content/blog/images/product-insights-van-der-waal-yellow-surfboard.jpg";
import imgProductTesting from "../../../../src/content/blog/images/product-insights-van-der-waal-product-testing.jpg";
import imgSmartSticker from "../../../../src/content/blog/images/product-insights-van-der-waal-smartsticker.jpg";
import imgSurfLisbon from "../../../../src/content/blog/images/product-insights-van-der-waal-surfing-in-lisbon.png";
import imgProductLine from "../../../../src/content/blog/images/product-insights-van-der-waal-product-line.png";
import ImageContainer from "../../../../src/components/blog/ImageContainer.jsx";
import * as React from 'react';
export default {
  imgVanDerWaalTeam,
  imgYellowSurfboard,
  imgProductTesting,
  imgSmartSticker,
  imgSurfLisbon,
  imgProductLine,
  ImageContainer,
  React
};