import imgWetsuitHanger from "../../../../src/content/blog/images/gnarwall-wetsuit-hanger.jpg";
import imgSuntribe from "../../../../src/content/blog/images/suntribe-reef-friendly-sun-stick.jpg";
import imgGnarwallVertical from "../../../../src/content/blog/images/gnarwall-vertical-surfbaord-wall-rack.jpg";
import imgFinBag from "../../../../src/content/blog/images/eisbach-riders-fin-bag-black-with-fsc-fiberglass-honeycomb-green-fin.jpg";
import imgWaxBoxSet from "../../../../src/content/blog/images/eisbach-riders-wax-zam-wax-box-set.jpg";
import imgGiftBox from "../../../../src/content/blog/images/christmas-gift-bundle-box.jpg";
import imgHorizontalHanger from "../../../../src/content/blog/images/gnarwall-horizontal-surfboard-wall-rack.jpg";
import imgMultiBoard from "../../../../src/content/blog/images/gnarwall-multi-rack-surfboard-wall-rack.jpg";
import imgGiftCard from "../../../../src/content/blog/images/roadtyping-greeting-card.jpg";
import imgOverview25 from "../../../../src/content/blog/images/gnarwall-surfboard-wall-rack-portfolio.jpg";
import ImageContainer from "../../../../src/components/blog/ImageContainer.jsx";
import * as React from 'react';
export default {
  imgWetsuitHanger,
  imgSuntribe,
  imgGnarwallVertical,
  imgFinBag,
  imgWaxBoxSet,
  imgGiftBox,
  imgHorizontalHanger,
  imgMultiBoard,
  imgGiftCard,
  imgOverview25,
  ImageContainer,
  React
};