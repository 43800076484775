import img1 from "../../../../src/content/blog/images/protecting-the-forest-1-percent-for-the-planet.jpg";
import img2 from "../../../../src/content/blog/images/surfing-croyde-bay-1-percent-for-the-planet.jpg";
import img3 from "../../../../src/content/blog/images/beach-clean-up-1-percent-for-the-planet.jpg";
import ImageContainer from "../../../../src/components/blog/ImageContainer.jsx";
import * as React from 'react';
export default {
  img1,
  img2,
  img3,
  ImageContainer,
  React
};