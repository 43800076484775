import imgEisbach from "../../../../src/content/blog/images/land-locked-surfing-at-eisbach-munich.jpg";
import imgAlmkanal from "../../../../src/content/blog/images/land-locked-surfing-at-almkanal-salzburg.jpg";
import imgThun from "../../../../src/content/blog/images/land-locked-surfing-at-obere-schleuse-thun.jpg";
import ImageContainer from "../../../../src/components/blog/ImageContainer.jsx";
import * as React from 'react';
export default {
  imgEisbach,
  imgAlmkanal,
  imgThun,
  ImageContainer,
  React
};