import imgEibsee from "../../../../src/content/blog/images/stand-up-paddling-tour-eibsee.jpg";
import imgTegernsee from "../../../../src/content/blog/images/stand-up-paddling-tour-tegernsee.jpg";
import imgAmmersee from "../../../../src/content/blog/images/stand-up-paddling-tour-ammersee.jpg";
import imgStarnberger from "../../../../src/content/blog/images/stand-up-paddling-tour-starnberger-see.jpg";
import imgChiemsee from "../../../../src/content/blog/images/stand-up-paddling-tour-chiemsee.jpg";
import imgWorthsee from "../../../../src/content/blog/images/stand-up-paddling-tour-worthsee.jpg";
import imgTourFriends from "../../../../src/content/blog/images/stand-up-paddling-tour-with-friends.jpg";
import ImageContainer from "../../../../src/components/blog/ImageContainer.jsx";
import * as React from 'react';
export default {
  imgEibsee,
  imgTegernsee,
  imgAmmersee,
  imgStarnberger,
  imgChiemsee,
  imgWorthsee,
  imgTourFriends,
  ImageContainer,
  React
};